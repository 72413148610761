<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">


    <create-edit-matricula-dialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <historico-matricula-dialog v-model="historicoDialogOpened" :matricula="item"/>
    <notificacoes-matricula-dialog v-model="notificacoesDialogOpened" :matricula="item"/>
    <div>
      <matriculas-data-table ref="dataTable"
                             @click:historico="verHistorico"
                             @click:notificacoes="verNotificacoes"
                             @click:edit="editar"
                             :searchValues="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import MatriculasDataTable from "../../components/shared/datatables/MatriculasDataTable";
import BaseView from "../BaseView";
import {findAll as findAllHorario} from '../../api/horarios';
import {findAll as findAllQuadra} from '../../api/quadras';
import {findAll as findAllLocal} from '../../api/locais';
import {findAll as findAllCurso} from '../../api/cursos';
import {findAll as findAllCliente} from '../../api/clientes';
import CreateEditMatriculaDialog from "../../components/shared/dialogs/CreateEditMatriculaDialog";
import HistoricoMatriculaDialog from "./components/HistoricoMatriculaDialog";
import NotificacoesMatriculaDialog from "./components/NotificacoesMatriculaDialog";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "index",
  props: ['cliente'],
  components: {HistoricoMatriculaDialog, NotificacoesMatriculaDialog, CreateEditMatriculaDialog, BaseView, MatriculasDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      historicoDialogOpened: false,
      notificacoesDialogOpened: false,
      searchValues: {},
      searchColumns: [
        {
          name: 'Cliente',
          column: 'idCliente',
          type: 'autocomplete',
          optionsFactory: (search) => findAllCliente(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.nome
                }))
              })

        },
        {
          name: 'Status Matrícula',
          column: 'stsMatricula',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.STATUS_PADRAO
          })
        },        
        {
          name: 'Tipo Plano',
          column: 'tipoPlano',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.TIPO_PLANO
          })
        },
        {
          name: 'Local',
          column: 'idLocal',
          type: 'autocomplete',
          optionsFactory: (search) => findAllLocal(1,{
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })

        },
        {
          name: 'Curso',
          column: 'idCurso',
          type: 'autocomplete',
          optionsFactory: (search) => findAllCurso(1,{
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })

        },
        {
          name: 'Quadra',
          column: 'idQuadra',
          type: 'autocomplete',
          optionsFactory: (search) => findAllQuadra(1,{
            descricao: search
          })
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.descricao
              })))
        },
        {
          name: 'Horario',
          column: 'idHorario',
          type: 'autocomplete',
          optionsFactory: () => findAllHorario(1)
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.range
              })))
        },
        {
          name: 'Com Notificações',
          column: 'exibirNotificados',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.FLG_PADRAO
          })
        },        
        {
          name: 'ID',
          column: 'id',
          type: 'number',
          prefix: '#'
        },        
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    verHistorico(item) {
      this.item = item;
      this.historicoDialogOpened = true;
    },
    verNotificacoes(item) {
      this.item = item;
      this.notificacoesDialogOpened = true;
    },
    criar() {
      this.item = {
        idCliente: null,
        idLocal: null,
        valMatricula: "",
        tipoPlano: "",
        diaVencimento: "",
        descMotivoDesconto: "",
        descNotificacoes: "",
        stsMatricula: "",
        valDesconto: null,
        dias: [],
        cursos: []
      };
      this.editDialogOpened = true;
    },
    editar(item) {
      this.item = JSON.parse(JSON.stringify({
        ...item,
        cursos: item.cursos.map(curso => ({
          id: curso.idCurso,
          descricao: curso.descricao_idCurso,
          qtdDias: curso.qtdDias
        }))
      }));
      this.editDialogOpened = true;
    },
    load() {
      if(this.cliente) {
        this.item = {
          dias: [],
          idCliente: this.cliente.id,
          nomeIdCLiente: this.cliente.nome
        }
        this.editDialogOpened = true;
      }
    }
  },
  mounted() {
    this.load();
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>