<template>
  <div>

    <responsive-menu ref="menu"
                     :title="selectedItemName"
                     :opcoes="menuOptions"
                     @click:item="action => $emit(`click:${action}`, selectedItem)"/>
    <v-data-table :headers="headers"
                  dense
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  @click:row="onClickRow"
                  @contextmenu:row.prevent="showMenu"
                  :footer-props="{
      disableItemsPerPage: true,
      itemsPerPageOptions: [itemsPerPage],
    }"
                  :items="elements" :loading="loading">
      <template v-slot:item.id="{item}">
        <strong>#{{ item.id }}</strong>
      </template>
      <template v-slot:item.descNotificacoes="{item}">
        <v-icon color="red" left>{{ item.descNotificacoes === '' ? '' : 'mdi-bell-badge-outline'}}</v-icon>
      </template>
      <template v-slot:item.stsMatricula="{item}">
        <p class="ma-0">{{ item.stsMatricula === 'A' ? 'Ativo' : 'Inativo' }}</p>
      </template>
      <template v-slot:item.cursos="{item}">
        <p class="ma-0">{{ item.cursos.map(d => d.descricao_idCurso+' - '+d.qtdDias+' dia(s)').join(', ') }}</p>
      </template>
      <template v-slot:item.valMatricula="{item}">
        <p class="ma-0">R$ {{item.valMatricula}}</p>
      </template>
      <template v-slot:item.valDesconto="{item}">
        <p class="ma-0">% {{item.valDesconto}}</p>
      </template>
      <template v-slot:item.valComDesconto="{item}">
        <p class="ma-0">R$ {{item.valComDesconto}}</p>
      </template>

      <template v-slot:item.dtcMatricula="{item}">
        {{ item.dtcMatricula | moment('DD/MM/YYYY') }}
      </template>

      <template v-slot:item.edit="{item}">
        <v-btn @click.prevent="showMenu($event, item)" small icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>


      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-list dense class="pa-0">
          <v-list-item @click="showMenu($event, item)" v-for="item of items" :key="item.id"
                       class="list-item">
            <v-list-item-content>
              <v-list-item-title v-text="item.nomeIdCliente"/>
              <v-list-item-subtitle>
                {{ item.cursos.map(d => d.descricao_idCurso).join(', ') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action-text>
              <v-chip color="primary" class="mr-2" input-value="false" small>R$ {{item.valComDesconto}}</v-chip>
            </v-list-item-action-text>
            <v-list-item-action-text>
              <v-chip small input-value="false" outlined>{{
                  item.stsMatricula === 'A' ? 'Ativo' : 'Inativo'
                }}
              </v-chip>
            </v-list-item-action-text>
            <v-list-item-action>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import {findAll} from '../../../api/matriculas';
import ResponsiveMenu from "../../app/ResponsiveMenu";

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "MatriculasDataTable",
  components: {ResponsiveMenu},
  props: {
    searchValues: {
      type: Object,
    },
  },
  data() {
    return {
      selectedItem: null,
      menuOptions: [
        {
          action: 'edit',
          text: 'Editar',
          icon: 'mdi-pencil'
        },
        {
          action: 'notificacoes',
          text: 'Notificações',
          icon: 'mdi-bell-badge-outline',
          color: 'red',
        },
        {
          action: 'historico',
          text: 'Histórico',
          icon: 'mdi-history',
          color: 'orange',
        },
      ],
      menu: {
        positionX: 0,
        positionY: 0,
        show: false
      },
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      headers: [
        {
          value: 'id',
          text: 'ID',
          align: 'start',
        },
        {
          text: 'Cliente',
          value: 'nomeIdCliente',
          align: 'start'
        },
        {
          value: 'descNotificacoes',
          text: 'Not.',
          align: 'start',
        },
        {
          text: 'Local',
          value: 'descricaoIdLocal'
        },  
        {
          text: 'Curso(s)',
          value: 'cursos'
        }, 
        {
          text: 'Data Matricula',
          value: 'dtcMatricula'
        },
        {
          text: 'Status',
          value: 'stsMatricula'
        },
        {
          text: 'Valor',
          value: 'valMatricula'
        },
        {
          text: 'Desconto',
          value: 'valDesconto'
        },
        {
          text: 'Valor Com Desconto',
          value: 'valComDesconto'
        },
        {
          text: 'Menu',
          width: 30,
          value: 'edit',
          align: 'end'
        }
      ],
    }
  },
  computed: {
    selectedItemName() {
      return this.selectedItem?.nomeIdCliente
    }
  },
  watch: {
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    showMenu(event, item) {
      this.selectedItem = !item.item ? item : item.item;
      this.$refs.menu.open(event);
    },
    onClickRow(row) {
      this.$emit('click:row', row);
    },
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAll(this.pageOptions.page, this.searchValues);

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;

      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    this.refresh();
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 132px);
    }
  }
}
.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}

</style>